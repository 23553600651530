<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <p-button type="info" v-show="showingProductType && !editProdVisible" round @click.prevent="hideProductType()">Back</p-button>
      </div>
      <div class="col-md-4 ml-auto">
        <form @submit.prevent="search" v-show="!addProductPanelVisible && !editProdVisible">
          <fg-input v-if="!loading" addon-right-icon="nc-icon nc-zoom-split" v-model="pagination.search"
                    placeholder="Search..."></fg-input>
          <fg-input v-if="loading" addon-right-icon="fa fa-circle-o-notch fa-spin" v-model="pagination.search" disabled="disabled"
                    placeholder="Search..."></fg-input>
        </form>
      </div>
    </div>

    <div class="row">
      <div v-show="!addProductPanelVisible && !editProdVisible && !showingProductType" style="padding: 20px;">
        <p-button type="primary" round @click.prevent="showHideAddProductPanel()">Add Product</p-button>
      </div>
      <div ref="editProd" class="col-md-12">
        <edit-product-variation-form v-if="editProdVisible" :user="user" :brand="activeBrand" :productType="editProdType" :product="editProd" :key="editProdKey" @prodUpdated="init" @cancel="cancelEditProd"></edit-product-variation-form>
      </div>
      <div v-show="addProductPanelVisible" class="col-md-12">
        <card style="max-width: 1340px;">
          <form class="form-horizontal">
            <h4 slot="header" class="card-title">
              Add Product to {{ activeBrand.name }}
              <p v-if="false && !maxReached" class="category">You can add up to {{ prodSlotsRemaining }} more products on your PLACEHOLDER Plan.</p>
              <p v-if="maxReached" class="category text-danger">Please upgrade your Authentic.net plan if you wish to add more brands.</p>
            </h4>
            <div class="row">
              <label class="col-md-3 col-form-label">Image</label>
              <div class="col-md-9">
                <div style="width: 200px;">
                  <single-image-uploader @tempFileIdUpdated="setTempImageId" />
                </div>
              </div>

              <label class="col-md-3 col-form-label">Name</label>
              <div class="col-md-9">
                <fg-input v-model="newProduct.name"></fg-input>
              </div>

              <label class="col-md-3 col-form-label">Redirect URL</label>
              <div class="col-md-9">
                <fg-input placeholder="ex: https://www.mydomain.com/landingpage" v-model="newProduct.redirect_url"></fg-input>
              </div>
            </div>

            <div class="row" v-for="(variation, index) in newProduct.variations" :key="'variation-' + index">
              <label class="col-sm-2 col-form-label">Variation</label>
              <div class="col-sm-10">
                <div class="variation-header">
                  <p-button 
                    v-if="newProduct.variations.length > 1" 
                    type="danger" 
                    size="sm" 
                    icon 
                    round 
                    class="remove-variation-btn" 
                    @click="removeVariation(index)">
                    <i class="nc-icon nc-simple-remove"></i>
                  </p-button>
                </div>
                <fg-input placeholder="SKU (required)" v-model="newProduct.variations[index].sku"></fg-input>
                <fg-input placeholder="UPC" v-model="newProduct.variations[index].upc"></fg-input>
                <div class="row" v-for="(option, option_index) in newProduct.variations[index].options" :key="'variation-' + index + '-option-' + option_index">
                  <label class="col-sm-2 col-form-label">Attribute (optional)</label>
                  <div class="col-md-5">
                    <fg-input>
                      <el-select class="select-info"
                                size="large"
                                placeholder="select..."
                                v-model="newProduct.variations[index].options[option_index].name"
                                @input="changeAttributeName($event, index, option_index)">
                        <el-option v-for="option in attributeNames"
                                  class="select-danger"
                                  :value="option"
                                  :label="option"
                                  :key="option">
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                  <div class="col-md-5">
                    <fg-input
                      placeholder="Value (ex: Medium, Blue, Adult)"
                      v-model="newProduct.variations[index].options[option_index].value"
                      @keyup="checkVariationOptions(index, option_index)">
                    </fg-input>
                  </div>
                </div>

                <div class="row" v-for="(fnsku, fnsku_index) in newProduct.variations[index].fnskus" :key="'variation-' + index + '-fnsku-' + fnsku_index">
                  <label class="col-sm-2 col-form-label">Amazon FNSKU (optional)</label>
                  <div class="col-sm-5">
                    <fg-input>
                      <el-select class="select-info"
                                size="large"
                                placeholder="Amazon Marketplace"
                                v-model="newProduct.variations[index].fnskus[fnsku_index].marketplace">
                        <el-option v-for="option in amzMarketplaces"
                                  class="select-danger"
                                  :value="option"
                                  :label="option"
                                  :key="option">
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                  <div class="col-sm-5">
                    <fg-input
                      placeholder="Optional FNSKU (ex: B000F453)"
                      v-model="newProduct.variations[index].fnskus[fnsku_index].fnsku"
                      @blur="checkVariationFnskus(index, fnsku_index)">
                    </fg-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12" style="text-align: center;">
                <p-button type="primary" size="sm" round @click.prevent="addVariation()">Add Variation</p-button>
              </div>
            </div>

            <div slot="footer" class="row">
              <div class="col-md-12">

                <p-button v-if="!loading" type="primary" round @click.prevent="addProduct()">Submit</p-button>
                <p-button v-if="loading" type="primary" round disabled="disabled">
                  <i class="fa fa-circle-o-notch fa-spin"></i>
                  Submit
                </p-button>
                <p-button type="primary" round @click.prevent="cancelAddProduct()">Cancel</p-button>

              </div>
            </div>
          </form>
        </card> <!-- end card -->
      </div> <!-- end col-md-12 -->
    </div>

    <div class="row" v-if="showingProductType">
      <product-variations-table class="col-lg-12" :user="user" :products="shownProductType.products" :product-type="shownProductType" @editProduct="editProduct" @productTypeDeleted="init" />
    </div>

    <div class="row" v-if="!showingProductType">

      <div v-for="(productType, index) in productTypes" class="col-lg-3" :key="productType.id">
        <div class="card" style="padding-top:10px;">
          <div class="text-center" style="height: 80px; padding: 0px 10px;">
            <h5 v-tooltip="productType.name" style="text-transform: uppercase;">{{limitChars(productType.name)}}</h5>
          </div>
          <div class="text-center" style="height: 100px; display: flex; align-items: center;">
            <div class="img-container">
              <a href="#" @click.prevent="showProductType(productType)">
                <img v-if="productType.img" :src="productType.img" :alt="productType.name">
                <span v-if="!productType.img">{NO PRODUCT IMAGE}</span>
              </a>
            </div>
          </div>
          <div class="text-center" style="height: 120px;">
            &nbsp;
          </div>
          <hr>
          <div class="text-center" style="height: 100px;">
            <p-button type="primary" round size="sm" @click.prevent="showProductType(productType)">View All Variations</p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!showingProductType">
      <div class="col-sm-6 pagination-info">
        <p class="category">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.total}} entries</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.total"
                      @input="changePage">
        </p-pagination>
      </div>
    </div>

    <div v-if="false" class="row">
      <div class="col-md-4 pull-right">
        <div style="float: right;">
          <p class="category">Show Archived Products</p>
          <p-switch v-model="showArchived" type="primary" on-text="ON" off-text="OFF"></p-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ProductTypeService from 'src/services/productType.service'
  import ProductService from 'src/services/product.service'
  
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import _ from 'lodash'
  // import { Card } from 'src/components/UIComponents'

  // import swal from 'sweetalert2'
  // import {mapFields} from 'vee-validate'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import EditProductVariationForm from './Components/EditProductVariationForm.vue'
  import { uuid } from 'vue-uuid'
  import PPagination from 'src/components/UIComponents/Pagination.vue'

  import SingleImageUploader from 'src/components/SharedComponents/SingleImageUploader.vue'
  import ProductVariationsTable from 'src/components/Dashboard/Views/Account/Components/ProductVariationsTable.vue'

  export default{
    components: {
      PSwitch,
      EditProductVariationForm,
      PPagination,
      SingleImageUploader,
      ProductVariationsTable
    },
    computed: {
      // ...mapFields(['logoUrl',
      //   'name']),
      ...mapGetters({
        user: 'auth/user'
      }),
      maxReached () {
        return false
      },
      prodSlotsRemaining () {
        return 5
      },
      activeBrand () {
        return this.user.account.brands[_.findIndex(this.user.account.brands, b => { return b.id === this.user.options.active_brand_id })]
      },
      currentPage () {
        console.log('currentPage', this.pagination.currentPage)
      }
    },
    mixins: [NotifyMixin],
    data () {
      return {
        initialized: false,
        editProdVisible: false,
        editProdKey: null,
        editProdType: null,
        editProd: null,
        productTypes: [],
        newProduct: {
          name: '',
          redirect_url: '',
          imgTempFileId: '',
          variations: [
            {
              sku: '',
              upc: '',
              options: [
                {
                  name: '',
                  value: ''
                }
              ],
              fnskus: [
                {
                  marketplace: 'Amazon.com',
                  fnsku: ''
                }
              ]
            }
          ]
        },
        uploadProgress: 0,
        loading: false,
        modelValidations: {
          name: {
            required: true
          }
        },
        showArchived: false,
        addProductPanelVisible: false,
        shownProductType: [],
        showingProductType: false,
        pagination: {
          search: '',
          currentPage: 1,
          perPage: 16,
          total: 1,
          from: 0,
          to: 0
        },
        amzMarketplaces: [
          'Amazon.com',
          'Amazon.ca',
          'Amazon.com.mx',
          'Amazon.com.br',
          'Amazon.cn',
          'Amazon.in',
          'Amazon.jp',
          'Amazon.sg',
          'Amazon.com.tr',
          'Amazon.ae',
          'Amazon.sa',
          'Amazon.fr',
          'Amazon.de',
          'Amazon.it',
          'Amazon.nl',
          'Amazon.es',
          'Amazon.se',
          'Amazon.co.uk',
          'Amazon.com.au'
        ],
        attributeNames: [
          'color',
          'size',
          'style',
          'design',
          'length',
          'width',
          'height',
          'weight',
          'scent',
          'flavor',
          'quantity',
          'version',
          'signature',
          'thickness',
          'material',
          'other'
        ]
      }
    },
    methods: {
      async init () {
        await this.search()
        this.hideProductType()
        this.editProdVisible = false
        this.editProdKey = uuid.v4()
        this.initialized = true
      },
      setTempImageId (newId) {
        this.newProduct.imgTempFileId = newId
      },
      async getProductTypes () {
        let response = await ProductTypeService.index(this.user.options.active_brand_id)
        this.productTypes = response.data.data
        // console.log('got products', this.productTypes)
      },
      addVariation () {
        this.newProduct.variations.push({
          sku: '',
          upc: '',
          options: [
            {
              name: '',
              value: ''
            }
          ],
          fnskus: [
            {
              marketplace: '',
              fnsku: ''
            }
          ]
        })
      },
      removeVariation(index) {
        if (this.newProduct.variations.length > 1) {
          this.newProduct.variations.splice(index, 1);
        }
      },
      addOption (variation, variationIndex) {
        this.newProduct.variations[variationIndex].options.push([
          {
            name: '',
            value: ''
          }
        ])
      },
      addVariationOption (variationIndex) {
        this.newProduct.variations[variationIndex].options.push([
          {
            name: '',
            value: ''
          }
        ])
      },
      addFnsku (variation, variationIndex) {
        this.newProduct.variations[variationIndex].fnskus.push({
          sku: '',
          options: [
            {
              name: '',
              value: ''
            }
          ]
        })
      },
      addVariationFnsku (variationIndex) {
        this.newProduct.variations[variationIndex].fnskus.push({
          sku: '',
          options: [
            {
              name: '',
              value: ''
            }
          ]
        })
      },
      async addProduct () {
        try {
          this.loading = true
          // must prepare product data
          this.prepareProductData()
          
          // Filter out variations without SKUs
          const filteredVariations = this.newProduct.variations.filter(variation => 
            variation.sku && variation.sku.trim() !== ''
          );
          
          if (filteredVariations.length === 0) {
            this.showNotify('At least one variation with an SKU is required', 'warning', 'Validation Error')
            this.loading = false
            return
          }
          
          await ProductTypeService.create(this.user.options.active_brand_id, this.newProduct)
          this.resetNewProduct()
          this.showNotify('Product created', 'success', 'Success')
          this.loading = false
          this.cancelAddProduct()
          this.getProductTypes()
        } catch (e) {
          let msg = e.response.data.message
          if (e.response.data.errors) {
            const keys = Object.keys(e.response.data.errors)
            keys.forEach((key, index) => {
                msg += ' - ' + e.response.data.errors[key]
            });
          }
          this.showNotify(msg, 'danger', 'Failed')
          this.loading = false
        }
      },
      validateAddProduct () {
        this.loading = true
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.addProduct()
          } else {
            this.loading = false
          }
        })
      },
      editProductType (prodType) {
        this.editProdType = prodType
        this.editProdVisible = true
        this.editProdKey = uuid.v4()
        // window.scrollTo(0,0)
        this.$refs.editProd.scrollIntoView()
      },
      editProduct (prodType, prod) {
        this.editProdType = prodType
        this.editProd = prod
        this.editProdVisible = true
        this.editProdKey = uuid.v4()
        // window.scrollTo(0,0)
        this.$refs.editProd.scrollIntoView()
      },
      cancelEditProd () {
        this.editProdVisible = false
      },
      variationKey (variation, index) {
        return 'variation-' + index
      },
      optionKey (variation, variationIndex, option, optionIndex) {
        return 'variation-' + variationIndex + '-option-' + optionIndex
      },
      prepareProductData () {
        // for some reason the form input values for the dynamically added arrays
        // are available on the complex objects when directly accessing them in code below,
        // but missing when JSON.stringify is employed, so we manually go through and build
        // basic js objects then use Vue.set (this.$set) to manually replace the 'variations'
        // value holding an array of complex objects with our array of basic objects
        var variations = []
        for (var i = 0; i < this.newProduct.variations.length; i++) {
          let options = []
          for (var j = 0; j < this.newProduct.variations[i].options.length; j++) {
            options.push({
              name: this.newProduct.variations[i].options[j].name,
              value: this.newProduct.variations[i].options[j].value
            })
          }

          let fnskus = []
          for (var k = 0; k < this.newProduct.variations[i].fnskus.length; k++) {
            fnskus.push({
              marketplace: this.newProduct.variations[i].fnskus[k].marketplace,
              fnsku: this.newProduct.variations[i].fnskus[k].fnsku
            })
          }

          variations.push({
            sku: this.newProduct.variations[i].sku,
            upc: this.newProduct.variations[i].upc,
            options: options,
            fnskus: fnskus
          })
        }
        this.$set(this.newProduct, 'variations', variations)
      },
      resetNewProduct () {
        this.newProduct = {
          name: '',
          redirect_url: '',
          imgTempFileId: '',
          variations: [
            {
              sku: '',
              options: [
                {
                  name: '',
                  value: ''
                }
              ],
              fnskus: [
                {
                  marketplace: 'Amazon.com',
                  fnsku: ''
                }
              ]
            }
          ]
        }
      },
      showHideAddProductPanel () {
        this.addProductPanelVisible = !this.addProductPanelVisible
      },
      cancelAddProduct () {
        this.addProductPanelVisible = false
        this.resetNewProduct()
      },
      hasMultipleVariations (productType) {
        return productType.products.length > 1
      },
      showProductType (productType) {
        this.shownProductType = productType
        this.showingProductType = true
        this.cancelAddProduct()
      },
      hideProductType () {
        this.showingProductType = false
        this.shownProductType = {}
      },
      async deleteProduct (product, index) {
        product.deleting = true
        this.$set(this.shownProductType.products, index, product)
        try {
          await ProductService.destroy(this.user.options.active_brand_id, product.id)
          this.init()
        } catch (e) {
          this.showNotify(e.response.data.message, 'danger', 'Failed')
          product.deleting = false
          this.$set(this.shownProductType.products, index, product)
        }
      },
      async deleteProductTypeViaOnlyProduct (productType, index) {
        productType.products[0].deleting = true
        this.$set(this.productTypes[index], 0, productType.products[0])
        try {
          await ProductService.destroy(this.user.options.active_brand_id, productType.products[0].id)
          this.init()
        } catch (e) {
          this.showNotify(e.response.data.message, 'danger', 'Failed')
          productType.products[0].deleting = false
          this.$set(this.productTypes[index], 0, productType.products[0])
        }
      },
      limitChars (x) {
        if (x.length > 90) return x.substring(0, 87) + '...'
        return x
      },
      checkVariationOptions (variationIndex, optionIndex) {
        console.log('checking variation option', variationIndex, optionIndex)
        if (this.newProduct.variations[variationIndex].options[optionIndex].name !== undefined) {
          this.newProduct.variations[variationIndex].options[optionIndex].name = this.newProduct.variations[variationIndex].options[optionIndex].name.trim()
        } else {
          this.newProduct.variations[variationIndex].options[optionIndex].name = ''
        }
        if (this.newProduct.variations[variationIndex].options[optionIndex].value !== undefined) {
          this.newProduct.variations[variationIndex].options[optionIndex].value = this.newProduct.variations[variationIndex].options[optionIndex].value.trim()
        } else {
          this.newProduct.variations[variationIndex].options[optionIndex].value = ''
        }

        if (this.newProduct.variations[variationIndex].options[optionIndex].name.trim() === '' &&
            this.newProduct.variations[variationIndex].options[optionIndex].value.trim() === '') {
          console.log('name and value found to be blank')
          // if there is only 1 option field or if this is the last one, then leave it be
          if (this.newProduct.variations[variationIndex].options.length === 1 ||
              optionIndex === this.newProduct.variations[variationIndex].options.length - 1) {
            // do nothing
          } else {
            console.log('removing this option')
            this.newProduct.variations[variationIndex].options.splice(optionIndex, 1)
            this.$set(this.newProduct.variations, variationIndex, this.newProduct.variations[variationIndex])
          }
        } else {
          // either name or value of this option name/value set is not blank
          console.log('name or value not blank')
          if (optionIndex === this.newProduct.variations[variationIndex].options.length - 1) {
            console.log('adding new option')
            this.addVariationOption(variationIndex)
          }
          this.$set(this.newProduct.variations, variationIndex, this.newProduct.variations[variationIndex])
        }
      },
      checkVariationFnskus (variationIndex, fnskuIndex) {
        if (this.newProduct.variations[variationIndex].fnskus[fnskuIndex].marketplace !== undefined) {
          this.newProduct.variations[variationIndex].fnskus[fnskuIndex].marketplace = this.newProduct.variations[variationIndex].fnskus[fnskuIndex].marketplace.trim()
        } else {
          this.newProduct.variations[variationIndex].fnskus[fnskuIndex].marketplace = ''
        }
        if (this.newProduct.variations[variationIndex].fnskus[fnskuIndex].fnsku !== undefined) {
          this.newProduct.variations[variationIndex].fnskus[fnskuIndex].fnsku = this.newProduct.variations[variationIndex].fnskus[fnskuIndex].fnsku.trim()
        } else {
          this.newProduct.variations[variationIndex].fnskus[fnskuIndex].fnsku = ''
        }

        if (this.newProduct.variations[variationIndex].fnskus[fnskuIndex].marketplace.trim() === '' &&
            this.newProduct.variations[variationIndex].fnskus[fnskuIndex].marketplace.trim() === '') {
          console.log('name and value found to be blank')
          // if there is only 1 option field or if this is the last one, then leave it be
          if (this.newProduct.variations[variationIndex].fnskus.length === 1 ||
              fnskuIndex === this.newProduct.variations[variationIndex].fnskus.length - 1) {
            // do nothing
          } else {
            console.log('removing this option')
            this.newProduct.variations[variationIndex].fnskus.splice(fnskuIndex, 1)
            this.$set(this.newProduct.variations, variationIndex, this.newProduct.variations[variationIndex])
          }
        } else {
          // either name or value of this option name/value set is not blank
          console.log('name or value not blank')
          if (fnskuIndex === this.newProduct.variations[variationIndex].fnskus.length - 1) {
            console.log('adding new option')
            this.addVariationFnsku(variationIndex)
            this.$set(this.newProduct.variations, variationIndex, this.newProduct.variations[variationIndex])
          }
        }
      },
      async search () {
        this.loading = true;
        let response = await ProductTypeService.index(this.user.options.active_brand_id, this.pagination.currentPage, this.pagination.search)
        this.loading = false;
        this.hideProductType();
        let data = response.data;
        this.pagination.from = data.from
        this.pagination.to = data.to
        this.pagination.total = data.total
        this.pagination.currentPage = data.current_page
        this.pagination.perPage = data.per_page
        if (this.pagination.search !== '') {
          let search = this.pagination.search.toLowerCase()
          let prodTypes = []
          let match = false
          for (let i = 0; i < data.data.length; i++) {
            match = false
            let prodType = JSON.parse(JSON.stringify(data.data[i]))
            if (prodType.name.toLowerCase().includes(search)) {
              match = true
            } else {
              prodType.products = []
              for (let j = 0; j < data.data[i].products.length; j++) {
                let prod = data.data[i].products[j]
                if (this.propMatchesSearch(prod.upc, search) || this.propMatchesSearch(prod.sku, search)) {
                  match = true
                  prodType.products.push(prod)
                } else {
                  for (let k = 0; k < prod.fnsku_assignments.length; k++) {
                    if (this.propMatchesSearch(prod.fnsku_assignments[k].fnsku, search)) {
                      match = true
                      prodType.products.push(prod)
                    }
                  }
                }
              }
            }
            if (match) prodTypes.push(prodType)
          }
          this.productTypes = prodTypes
        } else {
          this.productTypes = data.data
        }
      },
      changePage (page) {
        this.pagination.currentPage = page
        this.search();
      },
      changeAttributeName(e, variationIndex, optionIndex) {
        this.$set(this.newProduct.variations[variationIndex], optionIndex, this.newProduct.variations[variationIndex].options[optionIndex])
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      propMatchesSearch (prop, search) {
        if (prop !== null && prop.toLowerCase().includes(search)) return true
        else return false
      },
    },
    watch: {
      user: function (newUser, oldUser) {
        // if active brand is changed, then user will update
        // so we know to get product types again (for active brand)
        this.init()
      },
      showArchived: function (newFrom, oldFrom) {
        // this.getBrands()
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
.img-container {
  max-width: 140px;
  max-height: 100px;
  margin: auto;
  text-align: center;
}
.img-container img {
  max-width: 150px;
  max-height: 100px;
}

.variation-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.remove-variation-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-variation-btn i {
  font-size: 12px;
}
</style>
